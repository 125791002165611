import React from "react";

const FeaturesPage = ({}) => {
  return (
    <div
              style={{fontFamily: "Inter,-apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,noto sans,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif"}}
    >
      <section className="hero is-medium is-primary">
        <div className="hero-body has-text-centered">
          <p className="title">full featured hospitality suite</p>
        </div>
      </section>
      {/* Top Section with Photo and Text */}
      <section className="section">
        <div className="container">
          
        </div>
      </section>
    </div>
  );
}

export default FeaturesPage;
