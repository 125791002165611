import React, { useState, useEffect } from 'react';
import { JsonToMjml, BlockManager, BasicType, AdvancedType } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import { MjmlToJson, ExtensionProps, StandardLayout } from 'easy-email-extensions';

import mjml from 'mjml-browser';

import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';

import Axios from 'axios';
import applyConverters from 'axios-case-converter';

const csrfElement = document.querySelector('meta[name="csrf-token"]')
const orgId = document.querySelector('meta[name="org-id"]')

const baseConfig = {
  baseURL: '/api',
  timeout: 60 * 1000,
  headers: {
    'X-CSRF-Token': csrfElement.content,
    'Org-Id': orgId?.content,
  }
}

const baseClient = Axios.create(baseConfig)

export class Client {
  static getEmailTemplate({id, token}) {
    return baseClient.get(`/email_templates/${id}`,
    {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
  }

  static updateEmailTemplate({id, values, htmlString, token, generatedResponseIdCurrent}) {
    return baseClient.put(`/email_templates/${id}`, {
      "editor_json": values,
      "html_raw": htmlString,
      "generated_response_id_current": generatedResponseIdCurrent,
    },
    {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
  }
}

// theme, If you need to change the theme, you can make a duplicate in https://arco.design/themes/design/1799/setting/base/Color
import '@arco-themes/react-easy-email-theme/css/arco.css';

const defaultValues = {
  subject: 'Welcome to Easy-email',
  subTitle: 'Nice to meet you!',
  content: BlockManager.getBlockByType(BasicType.PAGE)?.create({}),
};

const EmailTemplateEditor = ({id, initialValues, token}) => {
  const [editorJson, setEditorJson] = useState(initialValues)
  const [generatedResponseIdCurrent, setGeneratedResponseIdCurrent] = useState()

  useEffect(() => {
    const interval = setInterval(() =>{
      Client.getEmailTemplate({id, token}).then((resp) => {
        const response = resp.data;
 
        if (response?.generated_response_id !== response?.generated_response_id_current && response?.generated_mjml!==  undefined) {
          const generatedMjml = response?.generated_mjml
            ?.replace(/\\u003c/g, "<")
            ?.replace(/\\u003e/g, ">")
            ?.replace(/\\u0022/g, '"')
            ?.replace(/\\n/g, "\n")

          const generatedJson = MjmlToJson(
            mjml(generatedMjml, { validationLevel: 'strict' }).json
          );

          setEditorJson({
            ...editorJson,
            content: generatedJson,
          })
          setGeneratedResponseIdCurrent(response?.generated_response_id)
        }
      })
    }, 5*1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <EmailEditorProvider
      data={editorJson == null ? defaultValues : editorJson}
      height={'calc(100vh - 50px)'}
      autoComplete
      dashed={false}
    >
      {({ values }, {submit, restart}) => {
        const mjmlString = JsonToMjml({
          data: values.content,
          mode: 'production',
          context: values.content,
          dataSource: {},
        });
        // TODO we probably need to make it not happen every render
        // but only if things have changed? not sure
        const htmlString = mjml(mjmlString,{}).html
        Client.updateEmailTemplate({id, values, htmlString, token, generatedResponseIdCurrent})
        return (
          <StandardLayout
            showSourceCode={true}
          >
            <EmailEditor />
          </StandardLayout>
        );
      }}
    </EmailEditorProvider>
  )
}

export default EmailTemplateEditor
