import React from "react"
import PropTypes from "prop-types"

import ContactForm from "./annwn/ContactForm"
import PrivacyNotice from "./annwn/PrivacyNotice"

import "../stylesheets/annwn.scss"

const LandingPage = ({}) => {
  return (
    <div
              style={{fontFamily: "Inter,-apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,noto sans,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif"}}
>
      {/* Top Section with Photo and Text */}
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <figure className="image">
                <img src="/images/logo.png" alt="Placeholder" />
              </figure>
            </div>
            <div className="column">
              <h1 className="title" style={{fontFamily: "Inter,-apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,noto sans,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif", direction: 'rtl', textAlign: 'justify'}}>complete<br />technology<br/>partner</h1>
            </div>
          </div>
        </div>
      </section>

      {/* Hero Section */}
      <section className="hero is-medium is-secondary">
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title">
              boutique hotels provide a stay unlike any other. <br/>we can enhance that
            </p>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns is-centered" style={{paddingTop: '5rem'}}>
            <div className="column is-half">
              <div className="card is-rounded" style={{height: '100%'}}>
                <div className="card-content">
                  <p className="title is-4">full featured property management system</p>
                  <p className="subtitle is-6">amplifying the boutique hotel experience for both guest and operator</p>
                  <figure className="image">
                    <img src="/images/man-statue-arms-1.png" alt="statue of man with arms in position"  style={{borderRadius: '1rem'}}/>
                  </figure>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="card is-rounded" style={{height: '100%'}}>
                <div className="card-content" style={{height: '90%'}}>
                  <p className="title is-4">we provide a comprehensive solution tailored to boutique hotels</p>
                  <div className="content mt-4">
                    <ul>
                      <li>landing page for the property</li>
                      <li>embeddable booking engine</li>
                      <li>channel manager</li>
                      <li>property management system</li>
                      <li>guest app</li>
                      <div>
                        <ul>
                          <li>check-in/check-out with passport & identity verification (through <a href="https://www.veriff.com">veriff</a>)</li>
                          <li>loyalty program</li>
                          <li>stay settings & service requests</li>
                          <li>booking another stay</li>
                          <li>guest tours</li>
                        </ul>
                      </div>
                    </ul>
                    <div className="has-text-centered">
                      <a className="button is-primary" href="/features"
                style={{fontFamily: "Inter,-apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,noto sans,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif"}}
  >see all features</a>
                    </div>
                  </div>
                </div>
                <footer className="card-footer">
                  <p className="card-footer-item">
                    <span>
                      $1 USD / guest / night
                    </span>
                  </p>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Demo link section */}
      <section className="hero is-medium">
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title is-secondary has-text-weight-bold">
              take a peek at our demo
            </p>
            <a className="button is-primary" href="/demo_page">demo</a>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="card is-rounded" style={{padding: '1rem'}}>
            <div className="card-content" style={{height: '100%'}}>
              <h1 className="title">who we work with</h1>
              <div className="columns is-vcentered is-centered">
                <div className="column is-narrow">
                  <a href="https://noncommon.group/">
                    <img
                      src="https://stay.annwn.app/images/non_common_group/logo.png"
                      alt="Main Logo"
                      style={{ maxWidth: '250px', marginBottom: '3rem'}}
                    />
                  </a>
                </div>
                <div className="column is-narrow is-flex is-flex-direction-column is-align-items-flex-start">
                  <a href="https://casamiravalle.com.mx/">
                    <img
                      src="https://stay.annwn.app/images/casa_miravalle/2_line_logo.png"
                      alt="Casa Miravalle Logo"
                      style={{ height: '8em', objectFit: 'contain' }}
                    />
                  </a>
                  <a href="https://casaolivamx.com">
                    <img
                      src="https://stay.annwn.app/images/casa_oliva_logo.png"
                      alt="Casa Oliva Logo"
                      style={{ height: '8em', objectFit: 'contain' }}
                    />
                  </a>
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <ContactForm />
        </div>
      </section>
      <PrivacyNotice />
    </div>
  )
}


export default LandingPage
