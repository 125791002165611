import React from "react"
import PropTypes from "prop-types"

import ContactForm from "./annwn/ContactForm"
import PrivacyNotice from "./annwn/PrivacyNotice"

import initMixpanel from './utilities/mixpanel.js';

const DemoPage = ({}) => {
  const mixpanel = initMixpanel('8228216aa14fcbb07db16364906636b6');
  const onClick = () => {
    mixpanel.track('demo_button_clicked')
    window.open(
      "https://stay.annwn.app/demo_link",
      "_self"
    )
  }
  return (
    <div
              style={{fontFamily: "Inter,-apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,noto sans,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif"}}
>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="content">
              <p>
                <h1>Check out our demo</h1>
                fyi. the environment resets every 15 minutes<br/><br/>
                <button onClick={onClick} className="button is-primary">Check in demo</button>
              </p>
              <p><i>Demo User Credentials</i></p>
              <p>email: demo@annwn.app</p>
              <p>password: 12341234</p>
              <br />
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <PrivacyNotice />
    </div>
  )
}


export default DemoPage
